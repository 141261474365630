<template>
  <div class="Forbidden">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>
    <div class="display-3 mt-5">Forbidden.</div>
  </div>
</template>

<script>
export default {
  name: 'Forbidden',
  data () {
    return {
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Forbidden',
          active: true
        }
      ],
    }
  },
}
</script>

<style>
  .paragraph-text {
    font-size: 16px;
  }
</style>